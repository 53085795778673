@import '../../styles/colors';
@import '../../styles/screenBreakpoints';

.order-placed {
  // width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 300px);

  .order-placed-card {
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 5px #5a5a5a29;
    border: 1px solid #e5e8ee;
    border-radius: 10px;
    width: 450px;
    padding: 30px;

    h2 {
      margin-top: 0px;
      margin-bottom: 10px;
    }

    p {
      text-align: center;
    }

    button {
      margin-top: 20px;
    }
  }
}

@media (max-width: $vp-4) { 
  // height: 300px;
  .order-placed {
    height: calc(100vh - 210px);

    .order-placed-card {
      box-shadow: none;
      border: none;
      width: 100%;
      margin-top: 50px;

      p {
        font-size: 15px;
      }
    }
  }
}