@import "../../styles/screenBreakpoints";

svg.ob-md-back-btn {
  cursor: pointer;
  max-height: 25px;
  padding-right: 17px;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;

  @media (max-width: $vp-7) {
    padding-right: 15px;
    cursor: pointer;
    max-height: 25px;
    
    @media (max-width: $vp-4) {
      padding-left: 0px;
      padding-right: 10px;
      max-height: 21px;
    }
  }
}
