@import '../../../styles/colors';
@import '../../../styles/screenBreakpoints';

.cf-order-wizard-customer-summary {
  display: flex;
  flex-direction: column;
  // border: 0.01rem solid;
  // border-color: #cccc;
  // border-radius: 20px;
  // padding: 30px;
  // width: 100%;
  // margin-right: auto;
  // margin-left: auto;

  // @media (max-width: 768px) { 
  //   width: 70vw;
  // }


  a {
    text-decoration: inherit;
    color: inherit;
  }

  b {
    margin-bottom: 5px;
  }

  .cf-order-wizard-customer-summary-row {
    display: flex;
    flex-direction: column;

    .cf-order-wizard-customer-summary-sub-row {
      color: $semi-black;
    }

    .logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;;
    }

    .back-arrow {
      color: var(--merchant-color);
      margin-left: auto;
      cursor: pointer;
    }
  }

  //FOR ACTUAL ORDER SUMMARY PAGE
  .basket {
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;

    .cf-order-wizard-customer-summary-item-summary-element {
      display: grid;
      width: 100%;
      grid-template-columns: 2.5fr 15.4fr 2fr 4fr;
      grid-row-gap: 17px;
      margin-top: 13px;
      grid-column-gap: 10px;

      &.no-images {
        grid-template-columns: 15.4fr 2fr 4fr;
      }

      .product-image-container {
        img {
          height: 66px;
          width: 66px;
          object-fit: cover;
          border-radius: 5px;
    
          @media (max-width: $vp-4) { 
            height: 50px;
            width: 50px;
          }
        }
      }

      .product-name-container {
        word-break: break-all;
        overflow-wrap: break-word;
        // font-weight: 600;
      }

      div {
        display: flex;
        align-items: center;
      }
    }

    .total {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 22px;
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #E5E8EE;
    }

    .sub-items {
      display: flex;
      justify-content: space-between;
    }

    .subtotal {
      div {
        display: flex;
        justify-content: space-between;
      }
      
      border-top: 1px solid #E5E8EE;
      margin-top: 15px;
      padding-top: 15px;
    }

    b  {
      margin-bottom: 0px;
    }
  }
}

.cf-order-wizard-customer-summary-from-wizard {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: inherit;
    color: inherit;
  }

  b {
    margin-bottom: 5px;
  }

  .cf-order-wizard-customer-summary-row {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    .cf-order-wizard-customer-summary-sub-row {
      color: $semi-black;
    }

    .logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;;
    }

    .back-arrow {
      color: var(--merchant-color);
      margin-left: auto;
      cursor: pointer;
    }
  }


  //FOR PRE CONFIRMATION ORDER SUMMARY
  .basket {
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;

    .cf-order-wizard-customer-summary-item-summary-element {
      display: grid;
      width: 100%;
      grid-template-columns: 2.5fr 15.4fr 2fr 4fr;
      grid-row-gap: 10px;
      margin-top: 13px;
      grid-column-gap: 10px;

      &.no-images {
        grid-template-columns: 15.4fr 2fr 4fr;
      }

      .product-image-container {
        img {
          height: 66px;
          width: 66px;
          object-fit: cover;
          border-radius: 5px;
          // margin-left: 15px;
    
          @media (max-width: $vp-4) { 
            height: 50px;
            width: 50px;
          }
        }
        .product-name-container {
          max-width: 70%;
        }
      }

      div {
        display: flex;
        align-items: center;
      }
    }

    .total {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #E5E8EE;
    }

    .subtotal {
      div {
        display: flex;
        justify-content: space-between;
      }
      
      border-top: 1px solid #E5E8EE;
      margin-top: 15px;
      padding-top: 15px;
    }

    b  {
      margin-bottom: 0px;
    }
  }

  .cf-footer {
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    font-size: 12px;

    img {
      height: 27px;

      @media (max-width: 768px) { 
        height: 15px;

      }
    }
  }
}

.cf-order-wizard-body {
  .cf-order-wizard-content {
    .cf-order-wizard-customer-summary-row.basket {
      margin-top: 0px;
    }
  }
}
