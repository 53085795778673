@import '../../styles/colors';

.display-quantity {
  display: flex;
  align-items: center;
  color: #000000C6;

  .display-quantity-controls {
    padding: 2px;
    border: 1px solid #E3E3E3;
    color: $ob-gray;
    cursor: pointer;
    text-align: center;
    width: 30px;
    justify-content: center;

    &:hover {
      background-color: #E3E3E3;
    }
  }

  .error-text {
    color: red;
    margin-left: 8px;
    font-size: 11px;
  }

  .info-text {
    color: $ob-gray;
    margin-left: 8px;
    font-size: 11px;
  }
}