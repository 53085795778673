.cf-proof-of-payment {
  border-width: 2px !important;
  border-color: var(--merchant-color) !important;
  // border-color: #cccc;
  // border-radius: 20px;
  // padding: 30px;
  // width: 100%;

  
  .MuiFormGroup-root {
    width: 100%;

    .MuiFormControlLabel-root {
      width: 100%;
      margin-bottom: 10px;
      align-items: start;

      .MuiTypography-root {
        width: 90%;
        padding-top: 1%;
      }
    }
  }

  input[type=file] {
    display: none;
  }
  margin: auto;

  // @media (max-width: 768px) { 
  //   width: 70vw;
  // }

  .cf-proof-of-payment-body {
    width: 100%;

    .proof-button-upload-container {
      width: 100%;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .payment-option-options {
      width: 100%;

      .option-container {
        width: 100%;
        justify-content: space-between;
      }

      .option-text {
        justify-content: end;
        padding-left: 20%;
        font-weight: 600;
      }

      .qr-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .qr-text-container {
        display: flex;
        align-items: center;

        p {
          font-weight: 600;
          margin-bottom: 0px;
        }

        a {
          font-size: small;
        }
      }

      .qr-text {
        display: flex;
        align-items: center;
        font-weight: 600;
        margin-bottom: 0px;
      }

      .qr-img {
        margin: 15px;
        border-radius: 5px;
        border-color: black;
        border: 2px;
  
        img {
          max-width: 100%;  
          min-height: 40px;
          max-height: 600px;
          border-radius: 5px;
        }
      }

      .upload {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .upload-text {
        display: flex;
        align-items: center;
        font-weight: 600;
      }
    }
  }

  .fileselect {
    width: 350px;
    height: 125px;
    background-color: #cccc;
    margin-top: 15px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto; 
    margin-right: 2%;
    margin-bottom: 30px;

    &:hover {
      background-color: #eeee;
      cursor: pointer;
  
    }
  }

  .confirm-btn:not([disabled]) {
    background-color: var(--merchant-color);
    border: 2px solid var(--merchant-color);
    cursor: pointer;
  }

  .confirm-btn {
    justify-content: center;
    align-items: center;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: var(--merchant-color) !important;
  }
}