@import '../../styles/colors';
@import '../../styles/screenBreakpoints';


.out-of-stock-dialog {
  .MuiDialog-paper {
    border-radius: 20px;
  }

  .placed-error-details-header {
    font-size: 22px;
    font-weight: 700;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    align-items: center;


    @media (max-width: $vp-4) { 
      flex-direction: column;
      justify-content: center;
      margin-left: 0px;
      text-align: center;
    }

    svg {
      color: red;
      width: 50px;
      height: 50px;
    }
  }
}

.cf-order-wizard {
  .order-place-error {
    background-color: #FFF4B796;
    display: flex;
    padding: 20px 35px;
    border-radius: 10px;
    
    @media (max-width: $vp-4) { 
      padding: 15px 17px;
    }

    .order-place-error-icon {
      align-items: center;
      display: flex;
    }
    
    svg {
      color: red;
    }
  }

  .placed-error-details {
    display: flex;
    flex-direction: column;
    margin-left: 30px;

    @media (max-width: $vp-4) { 
      margin-left: 20px;
    }

    .placed-error-details-header {
      font-size: 16px;
      font-weight: 600;
  
      @media (max-width: $vp-4) { 
        font-size: 14px;
      }
    }
  }

  .cf-order-wizard-header {
    display:flex;
    align-items: center;
    border-bottom: 1px solid #d4d4d4;
    justify-content: space-between;
    padding-bottom: 14px;
    padding-top: 14px;
    margin-bottom: 15px;

    a {
      text-decoration: inherit;
      color: inherit;
    }

    .header-title {
      .super-title {
        color: $ob-light-gray;
        font-size: 14px;
      }
    }

    .stepper {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      color: var(--merchant-color) !important;

      .step-label {
        padding-bottom: 2px;
      }

      .step-count {
        font-size: 17px;
        font-weight: 600;
      }
    }
  }


  .cf-order-wizard-body {
    margin-top: 30px;

    // @media (max-width: $vp-4) {
    //   font-size: 15px;
    // }

    .step-header {
      font-size: 18px;
      margin-top: 30px;
      font-weight: 600;
      // color: $ob-gray;
    }

    .cf-order-wizard-footer {
      margin-top: 50px;
      text-align: center;
      display: flex;
      justify-content: space-evenly;

      @media (max-width: $vp-4) {
        justify-content: space-between;
      }

      .confirm-btn-white {
        display: flex;
        align-items: center;
        border-color: var(--merchant-color);
        color: var(--merchant-color);
        text-decoration: none;
        padding-left: 26px;

        svg {
          max-width: 21px;
          margin-right: 8px;
        }
      }

      .confirm-btn-white:hover {
        background-color: transparent;
      } 
    }
  }
}