@import '../../styles/widgets';

.signup-container {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // margin-top: 20px;
  width: 100vw;
  max-width: 500px;

  @media(max-width: 768px) {
    padding: 5px;
    width: auto;
  }
}

