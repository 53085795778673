.otp {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .spacedFormContainer {
    width: 20%;

  @media(max-width: 768px) {
    width: 45%;

  }
  }
}