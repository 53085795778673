@import '../../styles/colors';
@import '../../styles/screenBreakpoints';

.RRT__container {
  position: relative;
}

/****************************/
/******** tab styles ********/
/****************************/
.RRT__tabs {
  display: flex;
  flex-wrap: nowrap;
  // border-bottom: 1px solid #e6e6e6;
}

.RRT__accordion {
  flex-direction: column;
}

.RRT__tab {
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
  padding: 0.7em 1em;
}

.RRT__tab:focus {
  outline: 0;
  background-color: #e6e6e6;
  color: var(--merchant-color);
}

.RRT__accordion .RRT__tab {
  border-left-width: 1px;
}

.RRT__tab--first {
  border-left-width: 1px;
}

.RRT__tab--selected {
  background: #fff;
  font-weight: 600;
  color: var(--merchant-color);
}

.RRT__tab--selected:focus {
  background-color: #fff;
}

.RRT__tab--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.RRT__tab:focus {
  z-index: 2;
  background: transparent;
}

.RRT__tab--selected .RRT__removable {
  position: relative;
}

.RRT__tab--selected .RRT__removable-text {
  margin-right: 10px;
}

.RRT__tab--selected .RRT__removable-icon {
  position: absolute;
  font-size: 18px;
  right: 0.5em;
  top: 0.2em;
}

/****************************/
/********* panel styles *****/
/****************************/
.RRT__panel {
  // margin-top: -1px;
  // padding: 1em;
}

.RRT__panel--hidden {
  // display: none;
}

.RRT__accordion .RRT__panel {
  // margin-top: 0;
}

/****************************/
/******* showmore control ***/
/****************************/
.RRT__showmore {
  outline: 0;
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
  margin-left: -1px;
  position: relative;
}

.RRT__showmore--selected {
  border-bottom: 3px solid var(--merchant-color);
  font-weight: 600;
  color: var(--merchant-color);
}

.RRT__showmore-label {
  padding: 0.7em 1em;
  position: relative;
  // bottom: 1px;
  z-index: 1;
}

.RRT__showmore-list {
  position: absolute;
  right: -1px;
  top: 110%;
  display: none;
  background: #FFF;
  border-radius: 12px;
  min-width: 180px;
  color: #000;
  font-weight: 400;
}

.RRT__showmore-list--opened {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 12px;
  border-width: 1px;
  -webkit-box-shadow: -1px 2px 5px -1px rgba(0,0,0,0.62);
  -moz-box-shadow: -1px 2px 5px -1px rgba(0,0,0,0.62);
  box-shadow: -1px 2px 5px -1px rgba(0,0,0,0.62);
}

.RRT__showmore-list--opened .RRT__tab--selected {
  color: #000;
  font-weight: 600;
  background-color: #eee;
}

.RRT__showmore-list--opened .RRT__tab:focus {
  color: #000;
}

/****************************/
/********** inkbar **********/
/****************************/
.RRT__inkbar-wrapper {
  width: 100%;
}

.RRT__inkbar {
  position: relative;
  bottom: 0;
  height: 3px;
  margin-top: -3px;
  background-color: var(--merchant-color);
  transition: left 800ms cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 2;
}
