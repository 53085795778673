@import '../../styles/colors';
@import '../../styles/screenBreakpoints';

@media (max-width: $vp-4) {
  .cf-shop-products-container {
    a {
      .cf-shop-products-element {
        width: 150px !important;
        height: 200px !important;
        padding: 10px !important;
        margin-top: 18px !important;
        font-size: 15px !important;

        .product-img {
          width: 150px !important;
          height: 150px !important;
        }

        img {
          max-width: 150px !important;
          max-height: 150px !important;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .cf-shop-products-container {
    a {
      .cf-shop-products-element {
        width: 130px !important;
        height: 184px !important;
        padding: 6px !important;
        margin-top: 18px !important;
        font-size: 14.5px !important;

        .product-img {
          width: 130px !important;
          height: 130px !important;
        }

        img {
          max-width: 130px !important;
          max-height: 130px !important;
        }
      }
    }
  }
}

.cf-shop {

  .pagination-container {
    padding: 15px;
    box-sizing: border-box;
    // box-shadow: 1px 10px 10px #5A5A5A29;
    // border: 1px solid #E5E8EE;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-top: 15px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    svg {
      cursor: pointer;
      color: $ob-gray;
      &.selected, &:hover {
        color: $ob-purple;
      }
    }

    .page-btns {
      padding: 3px 6px;
      margin-left: 8px;
      margin-right: 8px;
      cursor: pointer;
      color: $ob-gray;

      .non-interactable {
        cursor: default;
      }

      &.selected, &:hover {
        background-color: $ob-purple;
        border-radius: 5px;
        color: #fff;
        svg {
          color:  $ob-purple;;
        }
      }
    }
  }
  .merchant-color {
    color: var(--merchant-color);
  }

  .inner-content-container {
    .product-header-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-top: 20px;
      margin-bottom: 17px;
    }
  }

  .clear-search {
    color: rgb(215, 46,51);
    margin-left: auto;
    cursor: pointer;
    font-weight: bold;
  }

  .cf-shop-category-navigation-container {
    .cf-shop-category-navigation {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      // border-bottom: 1.5px solid #e5e8ee;

      &::-webkit-scrollbar {
        display: none;  /* Remove scrollbar space */
        background: transparent;
      }

      .cf-shop-category-navigation-element {
        cursor: pointer;
        padding-left: 19px;
        padding-right: 19px;
        white-space: nowrap;

        &.selected {
          color: var(--merchant-color);
          padding-bottom: 8px;
          border-bottom: 3px solid var(--merchant-color);
          font-weight: 600;
        }

        // @media (max-width: 768px) {
        //   min-width: 100px;
        // }
      }

      .change-section-id {
        cursor: pointer;
        svg {
          float: right;
        }
      }
    }
  }

  .cf-shop-products-container {
    a {
      text-decoration: inherit;
      color: inherit;
    }

    .cf-shop-products-element {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      background-color: red;
      width: 250px;
      height: 325px;
      padding: 10px;
      margin-top: 35px;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 16.5px;

      // .product-img {
      //   display: flex;
      //   width: 250px;
      //   height: 250px;
      //   justify-content: space-evenly;
      //   align-items: center;

      //   // zooming
      //   overflow: hidden;
      // }

      // img {
      //   display: flex;

      //   // resize and maintain AS, with crop
      //   object-fit: cover;
      //   // min-width: 280px;
      //   // max-width: 280px;
      //   // min-height: 280px;
      //   // max-height: 280px;
      //   width: 250px;
      //   height: 250px;

      //   // resize and maintain AS, no crop
      //   // object-fit: cover;
      //   // max-width: 250px;
      //   // max-height: 250px;
      //   // width: auto;
      //   // height: auto;
      // }

      .truncate {
        margin-bottom: 2px;
        word-wrap: break-word;
      }
    }
  }

  .paginate {
    .Mui-selected {
      background-color: var(--merchant-color);
      color: #FFF;
    }
    .Mui-selected:hover {
      background-color: var(--merchant-color);
      color: #FFF;
    }
  }

  // Hover animations
  .product-item {
    .product-img-container {
      overflow: hidden;
      width: 100%;
      border-radius: 5px;

      img {
        transition: transform .4s ease;
        display: block;
      }
    }
  }

  .product-item:hover {
    .product-img-container {
      overflow: hidden;
      width: 100%;
      border-radius: 5px;

      img {
        transform: scale(1.1);
        display: block;
      }
    }
  }
}