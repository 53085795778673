@import '../../styles/colors';
@import '../../styles/screenBreakpoints';

.cf-product-view {
  width: 100%;
  margin-top: 20px;

  .red {
    color: rgba(245, 16, 0, 0.685);
  }

  .product-display-view {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 4%;
    width: 100%;
    margin-top: 10px;

    .product-photo {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;

      img {
        object-fit: cover;
        max-width: 410px;
        max-height: 600px;
        width: auto;
        height: auto;
        border-radius: 5px;
      }
    }

    .product-description {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      .display-name {
        font-size: 26px;
        font-weight: 600;
        line-height: 1.35;
      }

      .display-price {
        color: var(--merchant-color);
        font-size: 26px;
        margin-top: 13px;
        font-weight: 600;
      }

      .desc-show {
        color: var(--merchant-color);
      }

      .display-description {
        margin-top: 30px;
        width: 75%;
        white-space: pre-wrap;
        word-wrap: break-word;  
      }

      .product-display-view-product-variants {
        color: $semi-black;

        .product-display-view-product-variants-row {
          display: flex;
          flex-wrap: wrap;
          margin-top: 30px;
          align-items: center;

          b {
            margin-bottom: 6px;
          }

          .product-display-view-product-variants-row-element {
            margin-right: 12px;
            margin-bottom: 8px;
            background-color: #F8F8F8;
            border: 1px solid #F8F8F8;

            border-radius: 5px;
            padding: 5px 8px;
            cursor: pointer;

            &.selected {
              color: var(--merchant-color);
              border: 1px solid var(--merchant-color);
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.product-display-view-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  .display-name {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.35;
  }

  .display-price {
    color: var(--merchant-color);
    font-size: 21px;
    margin-top: 5px;
    font-weight: 600;
  }

  .display-photo {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    border-radius: 5px;

    // display: flex;
    //   flex-direction: column;
    //   align-items: center;

    //   img {
    //     object-fit: cover;
    //     max-width: 410px;
    //     max-height: 600px;
    //     width: auto;
    //     height: auto;

    img {
      @media (max-width: $vp-7) {
        object-fit: cover;
        max-width: 460px;
        max-height: 550px;
        width: auto;
        height: auto;
        border-radius: 5px;
      }
      @media (max-width: $vp-4) {
        object-fit: cover;
        max-width: 330px;
        max-height: 50vh;
        width: auto;
        height: auto;
        border-radius: 5px;
      }
      @media (max-width: 350px) {
        object-fit: cover;
        max-width: 280px;
        max-height: 50vh;
        width: auto;
        height: auto;
        border-radius: 5px;
      }
    }
  }

  .display-description {
    margin-top: 30px;
    white-space: pre-wrap; 
    word-wrap: break-word;  
  }

  .display-quantity {
    display: flex;
    margin-top: 25px;
    margin-bottom: 30px;
    align-items: center;
    color: #000000C6;

    .display-quantity-controls {
      padding: 2px;
      border: 1px solid #E3E3E3;
      color: $ob-gray;
      cursor: pointer;
      text-align: center;
      width: 30px;

      &:hover {
        background-color: #E3E3E3;
      }
    }
  }

  .confirm-btn:not([disabled]) {
    padding: 10px 28px;
    font-size: 18px;
  }

  .product-display-view-product-variants {
    color: $semi-black;

    .product-display-view-product-variants-row {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      align-items: center;

      b {
        margin-bottom: 6px;
      }

      .product-display-view-product-variants-row-element {
        margin-right: 12px;
        margin-bottom: 8px;
        background-color: #F8F8F8;
        border: 1px solid #F8F8F8;

        border-radius: 5px;
        padding: 5px 8px;
        cursor: pointer;

        &.selected {
          color: var(--merchant-color);
          border: 1px solid var(--merchant-color);
          font-weight: 600;
        }
      }
    }
  }
}
