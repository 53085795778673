@import '../../../styles/screenBreakpoints';
@import '../../../styles/colors';

.shipping-menu-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 350px;
  align-items:center;

  @media (max-width: $vp-4) { 
    min-width: 0px;
  }

  .shipping-location {
    white-space: pre-line;
    max-width: 300px;

    @media (max-width: $vp-4) { 
      min-width: 0px;
      max-width: 200px;

    }
  }
}

.cf-order-wizard-shipping {
  p {
    font-size: 14px;
    color: $semi-black;
    margin-left: 45px;
    margin-top: 8px;
  }

 

  .step-header {
    margin-bottom: 10px;
  }

  .MuiFormControl-root {
    margin-top: 15px;
    margin-bottom: 25px;

    @media (max-width: $vp-4) { 
      margin-bottom: 10px;
    }
  }
}