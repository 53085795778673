.password-reset {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;

  .password-reset-card {
    box-shadow: 1px 10px 10px #5A5A5A29;
    border: 1px solid #E5E8EE;
    border-radius: 10px;
    width: 560px;
    padding: 50px;

  }
}