@import "../styles/colors";
@import "../styles/fonts";

.search-order-landing {
  z-index: 999;
  padding: 15px;
  box-shadow: 1px 10px 10px #5A5A5A29;
  border: 1px solid #E5E8EE;
  border-radius: 15px;
  background-color: #fff;
  width: 250px;
}

.testimonial-carousel{
  background-color: #f4f4f4;
  padding: 94px;
  text-align: center;
  font-size: 18px;
  color: #000000c6;

  @media (max-width: 768px) {
    padding: 40px;

  }

  ul.slick-dots {

    li.slick-active button:before{
      color: $ob-purple;
    }


    li button:before {
      font-size: 15px;
    }
  }

  .header {
    font-family: Kollektif;
    font-size: 36px;
    // font-size: 45px;
    margin-bottom: 15px;
    color: #272727de;

    // @media (max-width: 768px) {
    //   font-size: 40px;
    // }
  }

  .slick-next {
    @media (max-width: 768px) {
      right: -40px;
    }
  }

  .slick-prev {
    @media (max-width: 768px) {
      left: -40px;
    }
  }

  .carousel-item {
    .carousel-item2 {
      display: flex;
      justify-content: center;
      padding-top: 60px;

      @media (max-width: 768px) {
        flex-direction: column;
        padding-top: 25px;

      }

      img {
        height: 600px;
        width: 600px;

        @media (max-width: 768px) {
          height: 300px;
          width: 300px;
        }

      }

      .testimonial-container {
        flex-basis: 360px;
        text-align: left;
        margin-left: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 768px) {
          margin-left: 20px;
        
        }

        .testimonial-name {
          font-size: 31px;
          font-family: Kollektif;
        }
        .testimonial-text {
          font-size: 16px;
          @media (max-width: 768px) {
            font-size: 13px;
          }

          white-space: pre-line;
          a {
            text-decoration: none;
            color: $ob-purple;
          }
        }
      }
    }
  }
}

.logos-container { 
  margin-left: 100px;
  margin-right: 100px;

  @media (max-width: 768px) {
    margin-left: 45px;
    margin-right: 45px;
  }

  .many-more {
    font-family: 'Kollektif, regular';
    width: 100%;
    text-align: center;
    font-size: 28px;
    margin-top: 50px;
    margin-bottom: 50px;
  
    color: #00000099;
    letter-spacing: 0.92px;

    .number {
      color: $ob-purple;
      font-weight: 700;
    }

    @media (max-width: 768px) {
      font-size: 23px;

    }


  }

  .section-header {
    // font-size: 45px;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.58px;
    width: 100%;
    text-align: center;
    font-family: Kollektif;
    color: #272727de;
    margin-bottom: 80px;
    margin-top: 80px;

    @media (max-width: 768px) {
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > * {
      margin: 20px 40px;
      @media (max-width: 768px) {
        margin: 15px 10px;
      }
    }
    
    img {
      // height: 96px;
      // width: 96px;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      transition: box-shadow 150ms;

      @media (max-width: 768px) {
        height: 50px;
        width: 50px;
      }

      &:hover {
        box-shadow: 0px 0px 11px 1px rgba(87,87,87,0.4);
        -webkit-box-shadow: 0px 0px 11px 1px rgba(87,87,87,0.4);
        -moz-box-shadow: 0px 0px 11px 1px rgba(87,87,87,0.4);
      }
    }
  }
}

.landing-container {
  padding: 80px 120px;



  button.scrollTop {
    position: fixed;
    bottom: 25px;
    // right: 35px;
    border: 1px solid #e5e8ee;
    border-radius: 60px;
    background-color: #fff;
    z-index: 1;

    svg {
      color: #2a2a2a;
    }
  }

  @media (max-width: 768px) {
    padding: 50px 20px !important;
  }

  // @media (max-width: 1366px) {
  //   padding: 50px 70px !important;
  // }

  h1 {
    font-family: Kollektif;
  }

  .landing-header {
    display: flex;
    align-items: center;
    // margin-top: -20px;
    // margin-right: 41px;
    // margin-left: 41px;

    @media (max-width: 768px) {
      margin-right: 20px;
      margin-left: 20px;
    }

    h1.logo-title {
      color: $ob-purple;
      display: inline-block;
      margin: 0;
      margin-left: 30px;
      font-family: Kollektif;

      @media (max-width: 768px) {
        font-size: 23px;
        letter-spacing: 0.09px;
        margin-left: 20px;
      }
    }

    .right-nav {
      // float: right;
      display: flex;
      margin-left: auto;
      align-items: center;

      button {
        margin: 0px;
        font-size: 16px;
        padding: 7px 22px;
        border-radius: 10px;
      }

      @media (max-width: 768px) {
        display: none;
      }

      h4 {
        font-weight: 600;
        letter-spacing: 0.21px;

        a {
          text-decoration: none;
          color: black;
        }
        a:hover {
          color: $ob-purple;
        }
      }
    }

    .menu-btn {
      display: none;

      @media (max-width: 768px) {
        display: inline-block;
      }
    }

    img {
      height: 40px;
      width: 40px;
      @media (max-width: 768px) {
        height: 29px;
        width: 29px;
      }
    }
  }

  .section-header {
    font-size: 36px;
    // font-size: 45px;
    font-weight: 700;
    letter-spacing: 0.58px;
    width: 100%;
    text-align: center;
    font-family: Kollektif;
    color: #272727de;

    &.features {
      margin-top: 100px;
      margin-bottom: -50px;

      @media (max-width: 768px) {
        margin-top: 70px;
        margin-bottom: 0px;
      }
    }

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  .content {
    padding-top: 150px;
    display: flex;
    width: 100%;

    &.reverse {
      @media (max-width: 850px) {
        flex-direction: column-reverse;
        padding-top: 50px;
      }
    }

    @media (max-width: 850px) {
      flex-direction: column;
      padding-top: 50px;
    }

    .description-section {
      width: 300px;
      margin-bottom: 26px;
      color: #00000099;
      text-align: left;

      @media (max-width: 768px) {
        margin: auto;
        margin-bottom: 26px;
      }

      .descriptor {
        font-size: 18px;
        font-weight: 600;
        font-family: Kollektif;
        margin-bottom: 8px;
        color: #000000b5;
      }
    }

    .left {
      // width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      letter-spacing: 0.49px;

      img {
        height: auto;
        width: 650px;

        @media (max-width: 768px) {
          max-width: 334px;
          // max-height: 237px;
          margin: auto;
          margin-bottom: 28px;
        }
      }

      @media (min-width: 1280px) {
        margin-left: auto;
        padding-right: 140px;
      }

      @media (max-width: 768px) {
        // max-width: 335px;
        // padding-top: 30px;
        text-align: center;
      }

      .subheader {
        width: 325px;
        color: #000000c6;
        @media (max-width: 768px) {
          margin: auto;
        }
      }

      .get-notified {
        display: flex;
        justify-content: center;
        text-decoration: none;
        font-size: 24px !important;
        background-color: #fbd56e;
        width: 280px;
        border-radius: 17px;
        // padding: 25px;
        padding: 18px;
        margin-top: 41px;
        color: #272727de;
        cursor: pointer;
        height: auto;
        transition: box-shadow 150ms;


        h5 {
          margin: 0px;
        }

        @media (max-width: 768px) {
          max-width: 287px;
          margin: auto;
          margin-top: 30px;
        }
      }

      .get-notified:hover {
        box-shadow: 0px 0px 11px 1px rgba(87,87,87,0.4);
        -webkit-box-shadow: 0px 0px 11px 1px rgba(87,87,87,0.4);
        -moz-box-shadow: 0px 0px 11px 1px rgba(87,87,87,0.4);
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      .header-subtext {
        margin-top: 10px;
        color: #00000099;

        @media (max-width: 768px) {
          text-align: center;
        }

        a {
          color: $ob-purple;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .subheader {
        width: 325px;
        color: #000000c6;
        @media (max-width: 768px) {
          margin: auto;
        }
      }

      @media (max-width: 768px) {
        margin: auto;
        flex-direction: column;
      }

      @media (min-width: 1280px) {
        margin-right: auto;
      }

      .left-header {
        // .feature-header {
        //   @media (max-width: 768px) {
        //     text-align: center;
        //     width: 100%;
        //     font-size: 35px !important;
        //   }
        // }
        @media (max-width: 768px) {
          text-align: center;
          width: 100%;
          font-size: 34px;
        }

        &.setup-shop {
          @media (max-width: 768px) {
            margin-top: 30px;
          }
        }
      }

      img {
        height: auto;
        width: 670px;

        @media (max-width: 768px) {
          max-width: 334px;
          // max-height: 237px;
          height: auto;
          margin-bottom: 28px;
        }
      }
      #phone-stall {
        width: 570px !important;
        height: auto !important;
        @media (max-width: 740px) {
          max-width: 290px;
          padding-top: 80px;
          // max-height: 237px;
          height: auto;
          // margin-bottom: 20px;
        }
      }
    }
  }

  .left-header {
    font-size: 38px;
    font-weight: 700;
    letter-spacing: 0.58px;
    display: inline-block;
    font-family: Kollektif;
    color: #000000c6;

    @media (max-width: 768px) {
      font-size: 34px;
    }
  }

  .main-header {
    font-weight: 700;
    letter-spacing: 0.58px;
    display: inline-block;
    font-family: Kollektif;
    color: #000000c6;
    // font-size: 45px;
    font-size: 36px;

    
    @media (max-width: 768px) {
      font-size: 37px !important;
    }
  }

  .pricing-plan-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 5px #5a5a5a29;
    border: 1px solid #e5e8ee;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    width: 1000px;
    margin: auto;
    margin-bottom: 109px;
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 300px;
      margin-bottom: 66px;
    }

    .pricing-section {
      width: 33%;
      padding: 41px 34px;
      display: flex;
      flex-direction: column;
      height: 600px;

      @media (max-width: 768px) {
        height: auto; 
      }

      &.middle {
        border-left: 1px solid #dcdbdd;
        border-right: 1px solid #dcdbdd;

        @media (max-width: 768px) {
          border-bottom: 1px solid #dcdbdd;
          border-top: 1px solid #dcdbdd;
          border-left: none;
          border-right: none;
        }
      }

      @media (max-width: 768px) {
        width: 233px;
      }

      .pricing-plan-title {
        color: #000000a8;
        font-size: 24px;
        font-family: Kollektif;
        margin-bottom: 31px;
        min-height: 38px;
        display: flex;
        align-items: center;
      }

      .pricing-plan-entry {
        color: #000000c6;
        letter-spacing: 0.49px;
        margin-bottom: 19px;
        font-size: 15.5px;

        svg {
          margin-right: 18px;
        }
      }

      .pricing-plan-entry.center {
        text-align: center;
      }

      .footer {
        font-size: 15px;
        text-align: center;
        letter-spacing: 0.46px;
        color: #000000c6;
        // margin-top: 66px;
        margin-top: auto;

        @media (max-width: 768px) {
          padding-top: 30px;
        }
      }
    }
  }

  .use-orderbasket {
    letter-spacing: 0.77;
    font-size: 25px;
    width: 640px;
    margin: auto;

    @media (max-width: 768px) {
      font-size: 18px;
      width: 306px;
      margin-bottom: 50px;
    }
  }

}

// background: #FFFFFF 0% 0% no-repeat padding-box;
// box-shadow: 0px 4px 5px #5A5A5A29;
// border: 1px solid #E5E8EE;
// border-radius: 10px;

.schedule-free-demo {
  background-color: #f4f4f4;
  padding: 94px;
  text-align: center;
  font-size: 18px;
  color: #000000c6;

  @media (max-width: 768px) {
    padding: 50px;
  }

  .form-input-container {
    .MuiInputBase-root {
      background-color: white !important;
    }
  }

  .header {
    font-family: Kollektif;
    font-size: 36px;
    // font-size: 45px;
    margin-bottom: 15px;
    color: #272727de;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
  form {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 615px;
    margin: auto;
    margin-top: 52px;
    // margin-bottom: -60px;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 300px;
    }
    // .one-column {
    // width: 400px;
    // }

    .form-input-container {
      width: 299px;
      margin-bottom: 13px;

      @media (max-width: 768px) {
        max-width: 275px !important;
        margin: auto;
        margin-bottom: 13px;
      }

      &.left {
        margin-right: 15px;
      }
    }

    .confirm-btn-container {
      // margin:auto;
      width: 299px;
      // margin-bottom: 82px;

      margin-top: 0px !important;
      @media (max-width: 768px) {
        max-width: 275px !important;
        margin: auto;
        margin-bottom: 30px;
      }
    }
  }
}

.landing-footer {
  background-color: $ob-purple;
  text-align: center;
  color: white;
  padding: 80px;
  .icons {
    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    padding: 27px;
  }

  .footer-header {
    font-family: Kollektif;
    font-size: 31px;
    letter-spacing: 0.12px;
  }

  .footer-text {
    font-size: 13px;
  }
}

#first-panel {
  margin-top: -25px !important;
}

.MuiInputAdornment-root :not(.Mui-disabled) .MuiIconButton-label svg {
  color: $ob-purple;
}

video {
  width: 640px;

  @media (max-width: 768px) {
    width: 334px;
    padding-bottom: 40px;
  }
}

.thankyou {
  border: 1px soild #00000029;
  box-shadow: 0px 3px 6px #00000029;
  padding: 25px 30px;
  border-radius: 17px;
  background-color: #fff;
  color: #00000099;

  .header {
    font-family: Kollektif;
    font-size: 19px;
    color: #000000b5;
  }
}

// Shapes!

.shape-area-one {
  background-image: 
    url(./shapes/circle-yellow.svg),
    url(./shapes/ring-red.svg),
    url(./shapes/ring-purple.svg),
    url(./shapes/rectang-pink.svg),
    url(./shapes/shine-green.svg), 
    url(./shapes/wave-yellow.svg);
  
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat,no-repeat, no-repeat;
  
  background-position: 
    right -270px top 640px,
    right -95px top 960px,
    left -200px top 1500px,
    left -380px top 1200px,
    right 0px top 2000px,
    right -100px bottom -100px;

    @media (max-width: 768px) {
      // background-position: 
      //   right -290px top 640px,
      //   right -115px top 960px,
      //   left -220px top 1500px,
      //   left -400px top 1200px,
      //   right 0px top 2000px,
      //   right -80px bottom -100px;
    background-size: 0 0, 0 0, 0 0, 0 0, 0 0, 0 0;
    }
}



.shape-area-two {
  background-image: 
    url(./shapes/rectang-red.svg),
    url(./shapes/ring-yellow.svg);

  background-repeat: no-repeat, no-repeat;

  background-position: 
    right -480px top 280px,
    left -90px bottom -350px;

    @media (max-width: 768px) {
      // background-position: 
      //   right -290px top 640px,
      //   right -115px top 960px,
      //   left -220px top 1500px,
      //   left -400px top 1200px,
      //   right 0px top 2000px,
      //   right -80px bottom -100px;
    background-size: 0 0, 0 0;
    }
}