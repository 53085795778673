@import '../../styles/colors';
@import '../../styles/screenBreakpoints';

.customer-facing-header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: $vp-7) { 
    display: flex;
    justify-content: space-between;
  }

  .cf-navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      text-decoration: inherit;
      color: $ob-light-gray;
      margin-left: 26px;
      cursor: unset;
    }

    a.selected {
      font-weight: 600;
      color: var(--merchant-color);
      font-weight: 600;
    }

    a:hover:not(.selected) {
      cursor: pointer;
    }

    .navigation-item {
      @media (max-width: $vp-7) { 
        display: none;
        
        &.mobile {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          margin-bottom: 36px;
        } 
      }  
    }
  }

  .store-name-logo {
    display: flex;
    align-items: center;

    .logo {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }

    .merchant-name {
      text-decoration: none !important;
      margin-left: 18px;
      font-size: 26px;
      font-weight: 600;
      // color: var(--merchant-color);
      color: #212121;
    }
  }
  

  .menu-btn {
    display: none;

    @media (max-width: $vp-7) { 
      display: block;
    }
  }

  .my-cart {
    display: flex;
    align-items: center;
    margin-left: 26px;
    background-color: rgb(241, 241, 241);
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    // border: 2px solid;
    color: var(--merchant-color);
    font-weight: 600;

    svg {
      margin-right: 2px;
      max-width: 17px;
      height: auto;
      stroke-width: 2.5px;
    }
  }

  .my-cart:hover {
    background-color: rgb(236, 236, 236);
  }
}

@media (max-width: $vp-4) { 
  .store-name-logo {
    .logo {
      height: 35px !important;
      width: 35px !important;
    }

    .merchant-name {
      text-decoration: none !important;
      margin-left: 13px !important;
      font-size: 23px !important;
    }
  }
  .my-cart {
    margin-left: 15px !important;

    svg {
    }
  }
}

.cf-navigation.mobile {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 13px;

  .navigation-item {
    text-decoration: inherit;
    color: $ob-light-gray;
    cursor: pointer;
  }
  
  a {
    text-decoration: inherit;
    color: $ob-light-gray;
    margin-left: 26px;
    cursor: unset;
  }

  a.selected {
    font-weight: 600;
    color: var(--merchant-color);
    font-weight: 600;
  }

}