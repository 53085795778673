.account-created {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 350px;
    height: auto;

    @media(max-width: 450px) {
      width: 250px;
    }
  }
}