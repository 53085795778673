@import '../../../styles/colors';
@import '../../../styles/screenBreakpoints';

.cf-order-wizard-cart-container {
  display: flex;
  flex-direction: column;
  padding: 25px 0px 10px 0px;

  @media (max-width: $vp-4) {
    padding: 15px 0px 0px 0px;
  }

  .cf-order-wizard-cart-container-row {
    display: grid;
    grid-template-columns: .8fr 2.7fr 13fr 3fr;
    margin-bottom: 20px;

    @media (max-width: $vp-4) {
      margin-bottom: 19px !important;
      grid-template-columns: 1.3fr 3.5fr 11fr 3fr;
    }

    svg {
      max-width: 16px;
      color: $ob-light-gray;
    }

    .product-image-container {
      img {
        height: 70px;
        width: 70px;
        object-fit: cover;
        border-radius: 5px;

        @media (max-width: $vp-4) {
          height: 50px;
          width: 50px;
        }
      }
    }

    .cf-product-main {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: $vp-4) {
        margin-left: 4px;
      }
      .cf-product-name-container {
        display: block;
        width: 400px;
        margin-bottom: 13px;
        text-decoration: line-through;
        color: red;

        &.valid {
          color: black;
          text-decoration: none;
        }

        @media (max-width: $vp-4) {
          width: 205px;
          margin-bottom: 3px;
        }
      }
    }
  }

  div {
    display: flex;
    align-items: center;
  }
}

.cf-order-wizard-cart-container-total {
  border-top: 1px solid #ececec;
  font-weight: 600;
  padding-top: 15px;

  .cf-order-wizard-cart-container-total-value {
    // float: right;
    margin-left: auto;
  }

  .promo-field {
    border-radius: 12px;
  }
}

.valid-promo {
  border-radius: 8;
  border-width: 2;
  border-color: "#6adac6 !important";
}

.invalid-promo {
  border-radius: 8;
  border-width: 2;
  border-color: "#f9766a !important";
}