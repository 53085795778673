@import '../../styles/colors';

.upload-success {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .upload-success-card {
    box-shadow: 0px 4px 5px #5a5a5a29;
    border: 1px solid #e5e8ee;
    border-radius: 10px;
    // height: 300px;
    width: 500px;
    padding: 20px;
    flex-direction: column;

    @media (max-width: 768px) { 
      // height: 300px;
      width: 250px;
    }

    p {
      
    }
    
  }
  
}