.setup-your-shop {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .subHeaderText {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .spacedFormContainer {
    max-width: 500px;
    margin-bottom: 3px;

    @media(max-width: 768px) {
      width: 100%;
    }

    .guided-set-up-check {
      margin-top: 15px;
      margin-bottom: 25px;

      width: 100%;

      span.MuiTypography-root {
        margin-left: 15px;
      }

      svg.sparkle {
        margin-right: 10px;
      }
    }
  }
}
