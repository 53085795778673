@import '../styles/colors';
@import '../styles/screenBreakpoints';

.four-o-four {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 300px);

  .four-o-four-card {
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 5px #5a5a5a29;
    border: 1px solid #e5e8ee;
    border-radius: 10px;
    width: 450px;
    padding: 30px;

    h2 {
      margin-top: 0px;
      margin-bottom: 10px;
    }

    p {
      text-align: center;
    }

    .back-to-link {
      a {
        color: $ob-purple !important;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: $vp-4) { 

  .four-o-four {
    .four-o-four-card {
      box-shadow: none;
      border: none;
      width: 100%;
      margin-top: 50px;

      p {
        font-size: 15px;
      }
    }
  }
}

