@import '../../styles/colors';
@import '../../styles/screenBreakpoints';

.cf-about {
  .socials {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 20px;

    a {
      text-decoration: none;
      padding-left: 14px;
      color: var(--merchant-color);
      font-weight: 600;
    }
  }

  .center-text {
    text-align: center;
    margin: auto;

    @media (min-width: $vp-7) { 
      max-width: 85%;
    }
  }

  .store-tagline {
    color: var(--merchant-color);
    // font-weight: 600;
    font-size: 18px;

    @media (max-width: $vp-4) { 
      font-size: 16px;
    }
  }

  .center-text {
    text-align: center;
  }
}