@import '../../styles/colors';

.select-plan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  .pricing-plan-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 5px #5a5a5a29;
    border: 1px solid #e5e8ee;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    max-width: 800px;
    // margin: auto;
    margin-bottom: 40px;
    justify-content: center;
  
    margin-top: 40px;
    // display: flex;
  
    @media (max-width: 768px) {
      flex-direction: column;
      width: 300px;
      margin-bottom: 66px;
    }
  
    .pricing-section {
      // width: 50%;
      padding: 41px 34px;
      display: flex;
      flex-direction: column;
      height: 535px;
  
      @media (max-width: 768px) {
        height: auto; 
      }
  
      &.selectable {
        cursor: pointer;
        // &:hover {
        //   border: 3px solid $semi-black;
        //   // box-shadow: 5px 10px $semi-black;
        // } 
  
        &.selected {
          border: 3px solid $ob-purple;
          border-radius: 10px;
          // box-shadow: 5px 10px $ob-purple;
        }
      }
  
      &.middle {
        border-left: 1px solid #dcdbdd;
        border-right: 1px solid #dcdbdd;
  
        @media (max-width: 768px) {
          border-bottom: 1px solid #dcdbdd;
          border-top: 1px solid #dcdbdd;
          border-left: none;
          border-right: none;
        }
      }
  
      &.last {
        border-left: 1px solid #dcdbdd;
        // border-right: 1px solid #dcdbdd;
  
        @media (max-width: 768px) {
          border-bottom: 1px solid #dcdbdd;
          border-top: 1px solid #dcdbdd;
          border-left: none;
          border-right: none;
        }
      }
  
      @media (max-width: 768px) {
        width: 233px;
      }
  
      .pricing-plan-title {
        color: #000000a8;
        font-size: 24px;
        font-family: Kollektif;
        margin-bottom: 31px;
        display: flex;
        align-items: center;
      }
  
      .pricing-plan-entry {
        color: #000000c6;
        letter-spacing: 0.49px;
        margin-bottom: 19px;
        display: flex;
        align-items: center;
        // margin-left: 13px;
  
        svg {
          margin-right: 20px;
        }
      }
  
      .footer {
        font-size: 15px;
        text-align: center;
        letter-spacing: 0.46px;
        color: #000000c6;
        // margin-top: 66px;
        margin-top: auto;
  
        @media (max-width: 768px) {
          padding-top: 30px;
        }
      }
    }
  }
}