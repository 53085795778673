.cf-faq {
  .faq-row {
    // margin-left: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    // margin-bottom: 25px;
    border-bottom: 1px solid #dcdbdd;

    .question {
      margin-bottom: 10px;
    }

    div {
      // margin-bottom: 10px;
      white-space: pre-line;
    }
  }
}