@import '../styles/widgets';

.login-container {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  margin-top: 40px;
  // height: 70vh;

  .subHeaderText {
    margin-top: 10px;
    margin-bottom: 25px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-input-container {
      margin-bottom: 10px;
      width: 100%;
      min-width: 150px;
      width: 350px;
    }

    .submit.confirm-btn {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  @media(max-width: 768px) {
    padding: 5px;
  }

  
}