@import './colors';

input[type=text] {
  // width: 100%;
  // padding: 12px 20px;
  // margin: 8px 0;
  // box-sizing: border-box;
  // border-radius: 12px;
  // border-color: #dddd;
  // box-shadow: none;
}

input[type=number] {
  // width: 100%;
  // padding: 12px 20px;
  // margin: 8px 0;
  // box-sizing: border-box;
  // border-radius: 12px;
  // border-color: #dddd;
  // box-shadow: none;
}


input:focus,
select:focus,
textarea:focus,
button:focus {
    // outline: none;
}

input[type=password] {
  // width: 100%;
  // padding: 12px 20px;
  // margin: 8px 0;
  // box-sizing: border-box;
  // border-radius: 12px;
  // border-color: #dddd;
  // box-shadow: none;
}

.spacedFormContainer {
  padding: 8px;
  width: 100%;
}

.confirm-btn:not([disabled]) {
  color: #ffff;
  background-color: $ob-purple;
  border: 2px solid $ob-purple;
  text-decoration: none;
  padding: 10px 28px;
  font-size: 18px;
  font-weight: 700;
  transition-duration: 0.4s;
  border-radius: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  // @media(max-width: 768px) {
  //   padding: 5px 8px;
  //   font-size: 12px;
  // }

  &.small {
    font-size: 13px;
    padding: 5px 13px;
    border-radius: 5px;
  }
}

.faq-confirm-btn:not([disabled]) {
  color: $ob-purple;
  background-color: #ffff;
  border: 2px solid $ob-purple;
  text-decoration: none;
  padding: 10px 28px;
  font-size: 18px;
  font-weight: 700;
  transition-duration: 0.4s;
  border-radius: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 100%;
  
  &.small {
    font-size: 13px;
    padding: 5px 13px;
    border-radius: 5px;
  }
}


.confirm-btn:disabled {
  background-color: #dddd;
  border: 2px solid #0000;
  text-decoration: none;
  padding: 10px 28px;
  font-size: 18px;
  font-weight: 700;
  transition-duration: 0.4s;
  border-radius: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.confirm-btn:not([disabled]):hover {
  // color: $ob-purple;
  // background-color: #ffff;
  border: 2px solid $ob-purple;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button.confirm-btn-white:disabled {
  background-color: #dddd;
  color: rgba(16,16,16,0.3);
  border: 2px solid #0000;
  text-decoration: none;
  padding: 10px 28px;
  font-size: 18px;
  font-weight: 700;
  transition-duration: 0.4s;
  border-radius: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button.confirm-btn-white:not([disabled]) {
  color: $ob-purple;
  background-color: #ffff;
  border: 2px solid $ob-purple;
  text-decoration: none;
  padding: 10px 28px;
  font-size: 18px;
  font-weight: 700;
  transition-duration: 0.4s;
  border-radius: 12px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // @media(max-width: 768px) {
  //   padding: 5px 8px;
  //   font-size: 13px;
  // }

  &.small {
    font-size: 13px;
    padding: 5px 13px;
    border-radius: 5px;

  }
}

button.confirm-btn-red {
  color: #fff;
  background-color: rgb(215, 46,51);
  border: 2px solid rgb(215, 46,51);
  text-decoration: none;
  padding: 10px 28px;
  font-size: 18px;
  font-weight: 700;
  transition-duration: 0.4s;
  border-radius: 12px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // @media(max-width: 768px) {
  //   padding: 5px 8px;
  //   font-size: 13px;
  // }

  &.small {
    font-size: 13px;
    padding: 5px 13px;
    border-radius: 5px;

  }
}

button.confirm-btn-yellow {
  color: #fff;
  background-color: #f6d55c;
  border: 2px solid #f6d55c;
  text-decoration: none;
  padding: 10px 28px;
  font-size: 18px;
  font-weight: 700;
  transition-duration: 0.4s;
  border-radius: 12px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // @media(max-width: 768px) {
  //   padding: 5px 8px;
  //   font-size: 13px;
  // }

  &.small {
    font-size: 13px;
    padding: 5px 13px;
    border-radius: 5px;

  }
}



.confirm-btn-gray {
  color: $ob-purple;
  background-color: #f7f7f7;
  border: 2px solid #f7f7f7;
  text-decoration: none;
  padding: 10px 28px;
  font-size: 18px;
  transition-duration: 0.4s;
  border-radius: 12px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // @media(max-width: 768px) {
  //   padding: 5px 8px;
  //   font-size: 13px;
  // }

  &.small {
    font-size: 13px;
    padding: 5px 13px;
    border-radius: 5px;

  }
}

.confirm-btn-gray:hover {
  // color: #ffff;
  background-color: #ddd;
  // border: 2px solid #ffff;
}

button.confirm-btn-white:hover:not([disabled]) {
  color: #ffff;
  background-color: $ob-purple;
  border: 2px solid #ffff;
}

.headerText {
  font-size: 43px;
  font-weight: 400;
  text-align: center;
}

.subHeaderText {
  opacity: .6;
  text-align: center;
}

.ob-backbutton {
  
}