@import '../../styles/colors';
@import '../../styles/screenBreakpoints';

.customer-facing {
  width: 100%;

  .header-img {
    margin-top: 5px;
    border-radius: 10px;

    img {
      object-fit: cover;
      width: 100%;
      height: 30vw;
      min-height: 130px;
      max-height: 250px;
      border-radius: 10px;
    }
  }
}

.thank-you {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  background-color: #F4F4F4F4;
  margin-top: 15px;
}

.post-order {
  
  .post-order-order-status {
    margin-top: 40px;
    margin-bottom: 15px;
    border: 0.01rem solid;
    border-color: #dadce0;
    border-radius: 20px;
    padding: 30px 35px;


    @media (max-width: $vp-4) { 
      padding: 10px 0px;
      margin-bottom: 15px;

      border: 0;
      // border-bottom: 0.01rem solid;
      // border-color: #cccc;
      // border-radius: 0;
    }
  
    .header-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 13px;
  
      .options-btn {
        border: none;
        border-radius: 9px;
        padding: 5px 12px;
        background-color: rgb(240, 240, 240);
      }
  
      .options-btn:hover {
        cursor: pointer;
        background-color: rgb(235, 235, 235);
      }
    }
  
    .order-status-grid {
      display: grid;
      grid-template-columns: 210px auto;
      
      @media (max-width: $vp-4) { 
        grid-template-columns: 50% 50%;
        word-break: break-all;
        overflow-wrap: break-word;
      }
  
      .gray {
        padding-bottom: 10px;
        color: $ob-gray;
      }

      .keep {
        grid-template-columns: 210px auto;
        display: grid;
      }
  
      .order-status-tag {
        display: flex;
        align-items: center;
  
        svg {
          max-width: 16px;
          height: auto;
          margin-left: 10px;
          color: red;
        }
      }
    }

    .order-statuses {
      margin-bottom: 15px;
      display: grid;
      grid-template-columns: .8fr 2fr 6fr;
      grid-gap: 10px;

      .status-timestamp {
        color: #0000007c;
      }
      
      .status-indicator {
        display: flex;
        justify-content: center;
        align-items: center;

        .circle {
          border-radius: 50%;;
          background-color: #DDDDDD;
          height: 13px;
          width: 13px;
        }
      }
    }

    @media (max-width: $vp-4) { 
      .order-statuses {
        display: flex; 
        flex-wrap: wrap;
        grid-gap: 8px;
        margin-bottom: 16px;

        .status-indicator {
          width: 35px;
        }

        .status-timestamp {
          order: +1;
          margin-left: 44px;
        }

        .status-msg {
          width: 84%;
        }
      }
    }

    .status-index-0 {
      font-weight: 600;

      .circle {
        background-color: var(--merchant-color) !important;
      }
    }

    // .circle {
    //   position: relative;
    //   border-radius: 50%;
    //   width: 25%;
    //   height: 20%;
    //   padding-top: 15%;
    //   background-color: #BC235A;
    // }
  
    .subtext {
      text-align: center;
      font-size: 14.5px;
      color: $ob-gray;
  
      a {
        color: $ob-gray;
      }
    }
  }

  .gray-line {
    border-top: 1px solid #E5E8EE;
    margin-top: 15px;
  }
}  
