@import '../../components/styles/colors';
@import '../../components/styles/screenBreakpoints';

// Mui
.customer-facing-parent {
  .MuiInputBase-formControl.MuiInput-underline {
    &:after {
      border-bottom:2px solid var(--merchant-color);
    }
  }

  .MuiStepIcon-completed{
    color: var(--merchant-color) !important;
  }

  .MuiStepIcon-active{
    color: var(--merchant-color) !important;
  }

  .MuiStepConnector-active {
    .MuiStepConnector-line {
      border-color: var(--merchant-color) !important;
    }
  }

   .MuiStepConnector-completed {
    .MuiStepConnector-line {
      border-color: var(--merchant-color) !important;
    }
  }

  .fieldset.MuiOutlinedInput-notchedOutline {
    border-color: var(--merchant-color) !important;
  }

  .MuiFormControl-root {
    label.Mui-focused {
      color: var(--merchant-color) !important;
    }
    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--merchant-color) !important;
      }
    }
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: var(--merchant-color) !important;
  }
}

// colors
.customer-facing-parent {
  .confirm-btn:not([disabled]) {
    background-color: var(--merchant-color);
    border: 2px solid var(--merchant-color);
    cursor: pointer;
  }

  .confirm-btn-faq:not([disabled]) {
    background-color: var(--merchant-color);
    border: 2px solid var(--merchant-color);
    cursor: pointer;
    width: 100%;
  }

  // .confirm-btn:not([disabled]):hover {
  //   color: #fff;
  // }

  .contact-btn {
    background-color: var(--merchant-color);
    border: 2px solid var(--merchant-color);
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    padding: 10px 28px;
    font-size: 18px;
    font-weight: 600;
    transition-duration: 0.4s;
    border-radius: 12px;
  }

  .contact-btn:hover {
    box-shadow: 0px 0px 11px 1px rgba(87,87,87,0.4);
    -webkit-box-shadow: 0px 0px 11px 1px rgba(87,87,87,0.4);
    -moz-box-shadow: 0px 0px 11px 1px rgba(87,87,87,0.4);
  }

  .merchant-color {
    color: var(--merchant-color) !important;
  }
}

// layout
.customer-facing-parent {
  display: flex;
  flex-direction: column;
  margin: auto;
  min-height: 100vh;
  padding: 50px 70px;
  box-sizing: border-box;
  width: 100%;
  align-items: center;

  @media (min-width: $vp-9) {
    // max-width: $vp-9;
  }
  @media (min-width: $vp-12) {
    max-width: 1100px;
  }
  @media (max-width: $vp-7) {
    padding: 30px 50px;
  }
  @media (max-width: $vp-4) {
    padding: 30px 18px;
  }

  .customer-facing {
    width: 100%;

    .header-img {
      margin-top: 5px;
      margin-bottom: 15px;
      border-radius: 5px;

      img {
        object-fit: cover;
        width: 100%;
        height: 30vw;
        min-height: 130px;
        max-height: 250px;
        border-radius: 5px;
      }
    }
  }

  .cf-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;

    a {
      cursor: pointer;
      text-decoration: none;
      color: inherit;
    }

    img {
      height: 60px;
    }
  }

  // @media (max-width: $vp-7) {
  //   .cf-footer {
  //     margin-top: 50px !important;
  //     margin-bottom: 10px;

  //     img {
  //       height: 25px;
  //     }
  //   }
  // }

  .header-space {
    margin-top: 45px;

    @media (max-width: $vp-7) {
      margin-top: 40px;
    }

    @media (max-width: $vp-4) {
      margin-top: 35px;
    }
  }

  .spacer {
    flex-grow: 1;
  }

  .back-btn {
    margin-bottom: 15px;
    font-size: 16px;

    a {
      display: flex;
      align-items: center;
      color: $semi-black;
      cursor: pointer;
    }

    svg {
      max-width: 19px;
      height: auto;
      color: $semi-black;
      margin-right: 8px;
    }

    @media (max-width: $vp-4) {
      font-size: 18px;
    }
  }

  .cf-shop, .post-order {
    .section-label {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;

      @media (max-width: $vp-7) {
        font-size: 23px;
      }
    }
  }

  .cf-order-wizard {
    .section-label {
      font-size: 23px;
      font-weight: 600;

      @media (max-width: $vp-7) {
        font-size: 21px;
      }
    }
  }

  .cf-about, .cf-faq {
    .section-label {
      font-size: 23px;
      font-weight: 600;
      margin-top: 40px;
      margin-bottom: 15px;
    }

    // @media (max-width: $vp-7) {
    //   font-size: 21px;
    // }
  }
}

.customer-facing-parent {
  .cf-order-wizard {
    width: 100%;
    max-width: 680px;
  }
  .post-order {
    width: 100%;
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

b {
  font-weight: 600;
}

// button
@media (max-width: $vp-4) {
  .customer-facing-parent{
    .cf-order-wizard{
      .confirm-btn-white {
        font-size: 16px !important;
        padding: 8px 20px 8px 14px !important;
      }
    }

    .confirm-btn {
      font-size: 16px !important;
      padding: 8px 20px 8px 20px !important;
    }
  }
}

.plan-alert {
  width: 100%;
  background-color: rgb(255, 241, 164);
  font-size: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: none;
  padding: 15px;
  cursor: pointer;
  font-weight: 600;
}

.plan-alert.urgent {
  color: red;
}