$ob-purple: #A357C6;

$semi-black: #000000C6;

$ob-gray: #00000099;

$ob-light-gray: #0000008f;

.gray {
  color: $ob-gray;
}

.semi-black {
  color: $semi-black;
}

.ob-purple {
  color: $ob-purple;
}