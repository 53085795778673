@font-face {
  font-family: 'Kollektif';
  // font-style: normal;
  // font-weight: 400;
  src: url('/Kollektif-Bold.ttf'); /* IE9 Compat Modes */
  // src: local('Open Sans'), local('OpenSans'),
      //  url('Kollektif-Bold.ttf') format('embedded-opentype'), /* IE6-IE8 */
      //  url('Kollektif-Bold.ttf') format('woff2'), /* Super Modern Browsers */
      //  url('Kollektif-Bold.ttf') format('woff'), /* Modern Browsers */
      //  url('Kollektif-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
      //  url('Kollektif-Bold.ttf.svg#OpenSans') format('svg'); /* Legacy iOS */
}
