/* Gobal styles */
@import './styles/colors';

html, body {
  max-width: 100%;
  // overflow-x: hidden;
  // height: 100%;
}

strike {
  color: $ob-gray;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ob-purple {
  color: $ob-purple;
}

*{
  font-family: 'Open Sans', sans-serif;
} 

.MuiFormControlLabel-label, .MuiFormLabel-root, .MuiInputBase-root, .MuiTypography-body1, .MuiTypography-body2 {
  font-family: 'Open Sans', sans-serif !important;

}

.MuiFilledInput-root {
  background-color: #fff !important;
}

.MuiFilledInput-underline:after {
  border-bottom: none !important;
}

.MuiFilledInput-underline:before {
  border-bottom: none !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $ob-purple !important;
}

.MuiOutlinedInput-multiline {
  background-color: #fff;
  border-radius: 10px;
}

.MuiAccordionDetails-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 50px !important;
  max-height: 50px !important;
}

.MuiFormGroup-root {
  .MuiFormControlLabel-root {
    // align-items: flex-start;

    .MuiTypography-root {
      margin-left: 15px;
    }
  }
}

.MuiStepper-root {
  padding: 12px !important;
  
  .MuiTypography-root {
    font-size: 10px;
  }
}

.maintain-break {
  white-space: pre-line;
}


.container {
  display: flex;
  flex-direction: column;
  margin: 50px 100px;

  @media(max-width: 823px) {
    margin: 20px 50px;
  }
}

// .MuiDialog-paperWidthSm {
  // max-width: 900px !important;
// }

.MuiStepIcon-completed {
  color: $ob-purple !important;
}

.MuiStepIcon-active{
  color: $ob-purple !important;
}

li.MuiButtonBase-root.MuiListItem-root {
  padding-top: 3px;
}

h2.MuiTypography-root {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.MuiPickersBasePicker-container {
  .MuiPickersToolbar-toolbar {
    background-color: $ob-purple !important;
  }

  .MuiPickersDay-daySelected {
    background-color: $ob-purple !important;

  }
}

button:focus {outline:0;}

.MuiInputBase-formControl.MuiInput-underline {
  &:after {
    border-bottom:2px solid $ob-purple;
  }
}

.semi-bold {
  font-weight: 600;
}

.customer-toast {
  .custom-toast {
    background-color: var(--merchant-color) !important;
    color: white
  }
}

@font-face {
  font-family: 'Kollektif';
  // font-style: normal;
  // font-weight: 400;
  src: url('/Kollektif-Bold.ttf'); /* IE9 Compat Modes */
  // src: local('Open Sans'), local('OpenSans'),
      //  url('Kollektif-Bold.ttf') format('embedded-opentype'), /* IE6-IE8 */
      //  url('Kollektif-Bold.ttf') format('woff2'), /* Super Modern Browsers */
      //  url('Kollektif-Bold.ttf') format('woff'), /* Modern Browsers */
      //  url('Kollektif-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
      //  url('Kollektif-Bold.ttf.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Kollektif, regular';
  src: url('/Kollektif.ttf'); /* IE9 Compat Modes */
}


.Toastify__toast-container {
  max-width: 700px;
  min-width: 320px;
  width: auto !important;
  .Toastify__toast {
    padding: 18px !important;
    border-radius: 15px !important;
    font-family: 'Open Sans', sans-serif;
  
    &.Toastify__toast--success {
      background-color: $ob-purple;
      padding: 15px 25px;
      color: white;
      font-weight: 600;
  
    }
  }
}




